import React, { useContext, useEffect, useState } from "react";
import "../styles/sky.css";
import { DateObject } from "react-multi-date-picker";
import { AppContext } from "../contexts/AppContext";
import toast from "react-hot-toast";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { useModal } from "../contexts/ModalContext";
import CalendarModal from "../modals/CalendarModal";
import ConfirmModal from "../modals/ConfirmModal";
import ContextMenu from "./ContextMenu";
import ShareModal from "../modals/ShareModal";
import Avatar from "./Avatar";
import { UsersIcon } from "@heroicons/react/24/outline";
import restClient from "../restClient";

const useKeyboardHandler = (selectedNote, setSelectedNote, contextMenuNote, setContextMenuNote, setOnEdit, isModalOpen) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen()) return;

      if (contextMenuNote || selectedNote) {
        const actions = {
          'Escape': () => { setSelectedNote(null); setContextMenuNote(null); setOnEdit(false); },
        };
        actions[event.key]?.();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedNote, setSelectedNote, contextMenuNote, setContextMenuNote, setOnEdit, isModalOpen]);
};

const Notes = ({className}) => {
  const { notes, getNotes, editNote, insertNote, deleteNote, selectedNote, setSelectedNote, onEdit, setOnEdit, date, isPending, setIsPending } = useContext(AppContext);
  const { openModal, closeModal, isModalOpen } = useModal();
  const [calendarDate, setCalendarDate] = useState(new DateObject(date));
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [contextMenuNote, setContextMenuNote] = useState(null);

  useEffect(() => setCalendarDate(new DateObject(date)), [date]);

  const handleNoteClick = (event, note) => {
    // Menu dimensions
    const menuWidth = 160;
    const menuHeight = 182;

    // Heights of fixed elements
    const headerHeight = 113;
    const footerHeight = 65;

    // Get the click position and scroll offsets
    const x = event.clientX;
    const y = event.clientY;
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the new position ensuring the menu stays within the viewport
    const newX = Math.min(
      Math.max(x + scrollX - menuWidth, 0), // Prevent menu from going off the left
      window.innerWidth + scrollX - menuWidth // Prevent menu from going off the right
    );

    const newY = Math.min(
      Math.max(y + scrollY, headerHeight),
      window.innerHeight + scrollY - menuHeight - footerHeight // Prevent menu from going off the bottom
    );

    setMenuPosition({ x: newX, y: newY });
    setContextMenuNote(note);
  };


  const handleOutsideClick = (event) => {
    if (!event.target.closest('.note')) {
      setContextMenuNote(null);
    }
  };

  const handleScroll = () => setContextMenuNote(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener("click", handleOutsideClick);
    }
  }, []);

  const handleDelete = async () => {
    const response = await deleteNote(selectedNote.id);
    if (response) toast.success(response.data);
    closeModal();
    await getNotes();
  };

  const handleToggleComplete = async (note) => {
    await editNote(note.id, { completed: !note.completed });
    await getNotes();
  };

  const handleDateChange = async (newDate) => {
    const formattedDate = newDate.convert(gregorian, gregorian_en).format("YYYY-MM-DD");
    const response = await editNote(selectedNote.id, { date: formattedDate });
    if (response) toast.success("یادداشت موکول شد.");
    closeModal();
    await getNotes();
  };

  useKeyboardHandler(selectedNote, setSelectedNote, contextMenuNote, setContextMenuNote, setOnEdit, isModalOpen);

  const handleDeleteSharedNote = async (note) => {
    setIsPending(true);
    try {
      await restClient.post(`/share/delete`, { noteId: selectedNote.id });
      await getNotes();
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsPending(false);
    }
  };

  const handleBookmark = async (note) => {
    const { id, ...newNote } = note;
    newNote.date = '';
    const response = await insertNote(newNote);
    if (response) toast.success('یادداشت ذخیره شد.');
  };

  return (
    <div className={`flex flex-col pt-32 pb-16 overflow-y-auto ${className} ${isPending ? "pointer-events-none" : ""}`} style={{ paddingBottom: onEdit ? `${Math.min(Math.max(selectedNote?.content.length || 0, 44), window.innerHeight * 0.25)}px` : "" }}>
      {isPending &&<div className="absolute inset-0 flex items-center justify-center"><div className="w-32 h-32 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin" /></div>}
      {notes.map((note) =>
        <div key={note.id} className={`mb-4 px-2 md:px-4 overflow-x-hidde ${note.isSharedWithYou ? 'self-end' : 'self-start'} ${isPending ? 'opacity-25' : ''}`}>
          <div className={`animate-slide-bottom-to-top flex items-start gap-2 ${contextMenuNote?.id === note.id ? 'animate-pulse' : ''}`} >    
            <div className={`note cursor-pointer caret-transparent break-words bg-white text-lg border-4 rounded-t-md rounded-bl-2xl rounded-tl-2xl rounded-tr-2xl p-3 ${note.completed ? "line-through" : ""}`} style={{ maxWidth: "min(80vw, 900px)", borderColor: note.color, whiteSpace: "pre-wrap" }} onClick={(event) => handleNoteClick(event, note)}>
              {note.content}
            </div>
            {note.isSharedWithYou ? <Avatar className='mt-0.5' username={note.username} withText={false} circleSize={40} /> : null }
            {note.isSharedByYou ? <UsersIcon className="h-6 mr-[-1.4rem] bg-white rounded-full p-0.5"/> : null}
          </div>
          {contextMenuNote?.id === note.id &&
            <ContextMenu
              note={note}
              onDelete={() => { setOnEdit(false); setSelectedNote(note); openModal("deletionModal") }}
              onToggleComplete={() => { handleToggleComplete(note) }}
              onEdit={() => { setSelectedNote(note); setOnEdit(true) }}
              onOpenCalendar={() => { setOnEdit(false); setSelectedNote(note); openModal("calendarModal") }}
              onShareNote={() => {setOnEdit(false); setSelectedNote(note); openModal("shareModal")}}
              onDeleteSharedNote={()=> {setOnEdit(false); setSelectedNote(note); openModal("deleteSharedNoteModal")}}
              onBookmark={() => handleBookmark(note)}
              position={menuPosition}
            />
          }
        </div>
      )}
      <ConfirmModal id="deletionModal" onYes={handleDelete} question="پاک شود؟" />
      <ConfirmModal id="deleteSharedNoteModal" onYes={handleDeleteSharedNote} question="اشتراک‌گذاری لغو شود؟" />
      <CalendarModal onChange={handleDateChange} date={calendarDate} />
      <ShareModal id="shareModal" onAction={getNotes} />
    </div>
  );
};

export default Notes;
