import React from 'react';

const Button = ({ isLoading, onClick, children, loadingText = 'در حال اجرا...', disabled }) => {
    return (<button className={`select-none py-2 mt-2 text-center w-full rounded-3xl ${!disabled ? 'bg-[#86b4f0]' : 'bg-[#979797]'} ${!disabled ? 'hover:bg-[#7BA7E6]' : null}  ${!disabled ? 'active:bg-[#6093D8]' : null} text-lg text-slate-50 ${isLoading ? 'cursor-not-allowed' : ''}`} disabled={isLoading || disabled} onClick={onClick}>
        {isLoading ? (
            <div className="flex items-center justify-center gap-2">
                <div className="w-6 h-6 border-2 border-white border-solid rounded-full border-t-transparent animate-spin"></div>
                <div>{loadingText}</div>
            </div>
        ) : (children)}
    </button>
    );
};

export default Button;
