import { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { Calendar } from "react-multi-date-picker";
import { useState, useContext, useEffect } from "react";
import "../styles/sky.css";
import { AppContext } from "../contexts/AppContext";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { useModal } from "../contexts/ModalContext";

const DateViewModal = ({ id = 'dateViewModal'}) => {
  const { closeModal, isTopModal, openModal } = useModal();

  const { date, setDate, isPending, onBookmark } = useContext(AppContext);
  const [value, setValue] = useState(new DateObject(date).convert(persian, persian_fa));

  const formattedDate = value.format("DD MMMM YYYY");
  const weekDay = value.format("dddd");

  useEffect(() => { setValue(new DateObject(date).convert(persian, persian_fa)) }, [date])

  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    setDate(new DateObject(selectedValue).convert(gregorian, gregorian_en));
    closeModal();
  };

  const handleToday = () => {
    const today = new DateObject({ calendar: persian, locale: persian_fa });
    setValue(today);
    setDate(new DateObject(today).convert(gregorian, gregorian_en));
    closeModal();
  };  

  if (!isTopModal(id)){
    return (
      <div className="flex flex-col gap-4 justify-center">
        <div className="text-3xl text-stone-800 font-medium cursor-default caret-transparent">{!onBookmark ? weekDay : 'دفترچه'}</div>
        <div className={`flex mr-4 gap-3 text-stone-700 cursor-pointer hover:text-[#349ac8] ${isPending || onBookmark ? 'pointer-events-none' : ''}`} onClick={() => openModal(id)}>
          {!onBookmark && <CalendarIcon className="w-6" />}
          <div className={`${!onBookmark ? 'text-xl hover:text-[#349ac8]' : ''} caret-transparent text-stone-500`}>{!onBookmark ? formattedDate : 'یادداشت‌های بدون تاریخ'}</div>
        </div>
      </div>  
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="text-3xl text-stone-800 font-medium cursor-default caret-transparent">{weekDay}</div>
      <div className={`flex mr-4 gap-3 text-stone-700 cursor-pointer hover:text-[#349ac8] ${isPending ? 'pointer-events-none' : ''}`} onClick={() => openModal(id)}>
        <CalendarIcon className="w-6" />
        <div className="text-xl caret-transparent">{formattedDate}</div>
      </div>
      <div className="fixed inset-0 z-50 flex items-center justify-center" onClick={closeModal}>
        <div onClick={(e) => e.stopPropagation()}>

        <Calendar calendar={persian} locale={persian_fa} className="animate-expand-up sky absolute right-8 top-28" value={value} onChange={handleChange} monthYearSeparator="|" >
          <div className="flex gap-1 justify-evenly text-sm rounded-xl shadow-xl">
            <button className="mb-4 w-24 py-1 border text-[#ff4d4d] border-[#ff4d4d] shadow rounded-lg" onClick={closeModal} >انصراف</button>
            <button className="mb-4 w-24 py-1 border text-sky-400 border-sky-400 shadow rounded-lg" onClick={handleToday} >امروز</button>
          </div>
        </Calendar>
        </div>
      </div>
    </div>
  );
};

export default DateViewModal;
