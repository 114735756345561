import React from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import { useModal } from '../contexts/ModalContext';

const CalendarModal = ({ id = 'calendarModal', date, onChange }) => {
  const { closeModal, isTopModal } = useModal();

  const handleTomorrow = () => {
    const today = new DateObject(date);
    const tomorrow = today.add(1, 'day');
    onChange(tomorrow);
  };

  if (!isTopModal(id)) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center" onClick={closeModal}>
      <div className='motion-preset-expand motion-duration-300' onClick={(e) => e.stopPropagation()}>
        <Calendar
          calendar={persian}
          locale={persian_fa}
          className="sky"
          value={date}
          onChange={onChange}
          monthYearSeparator="|"
        >
          <div className="flex gap-1 justify-evenly text-sm rounded-xl shadow-xl">
            <button
              className="mb-4 w-24 py-1 border text-[#ff4d4d] border-[#ff4d4d] shadow rounded-lg"
              onClick={closeModal}
            >
              انصراف
            </button>
            <button
              className="mb-4 w-24 py-1 border text-sky-400 border-sky-400 shadow rounded-lg"
              onClick={handleTomorrow}
            >
              روز بعد
            </button>
          </div>
        </Calendar>
      </div>
    </div>
  );
};

export default CalendarModal;
